import styled from "styled-components";

export const Container = styled.div`
  padding-block: 200px;

  svg {
    width: 200px;
    height: 200px;
  }
`;
