import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { darkBlue, globalContainer, lightBlue } from "./../../root/global";

export const FooterDiv = styled.div`
  background-color: ${darkBlue};
`;

export const Container = styled.div`
  ${globalContainer}
  margin: 0 auto;
`;

export const FooterTop = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  ${Container} {
    display: flex;
    gap: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0;
    }
  }
  @media screen and (max-width: 575px) {
    padding-bottom: 48px;
  }
`;

export const LeftSide = styled.div`
  width: calc(30%);
  padding-top: 48px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

LeftSide.Title = styled.h2`
  display: block;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

const commonItemStyle = css`
  margin-top: 20px;
  display: inline-flex;
  gap: 20px;
  align-items: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;

export const ItemTop = styled.div`
  ${commonItemStyle};
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  ${commonItemStyle}
`;

export const ForIcon = styled.div`
  width: 18px;
  height: 18px;
`;

export const SocialMedia = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SocialLink = styled(Link)`
  width: 36px;
  height: 36px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 50%;
    height: 50%;
    path {
      fill: #fff;
    }
  }
  transition: 0.2s linear;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const RightSide = styled.div`
  width: calc(70% - 40px);
  display: flex;
  align-items: flex-start;
  gap: 30px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    gap: 0;
  }
`;

RightSide.Item = styled.div`
  width: calc(100% / 3 - 10px);
  margin-top: 48px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

RightSide.Title = styled.h2`
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

RightSide.Link = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  transition: 0.25s linear;
  :hover {
    color: ${lightBlue};
  }
`;

export const FooterBottom = styled.div`
  padding: 16px 0;
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
      text-align: center;
    }
  }
`;

export const ForLogo = styled(Link)`
  text-decoration: none;
  width: 117px;
  svg {
    width: 100%;
  }
`;

export const Copyright = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;
