import {
  FooterBottom,
  FooterDiv,
  FooterTop,
  Container,
  ForLogo,
  Copyright,
  LeftSide,
  RightSide,
  ItemTop,
  ItemLink,
  ForIcon,
  SocialMedia,
  SocialLink,
} from "./style";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Location } from "../../assets/icons/footer/location.svg";
import { ReactComponent as Phone } from "../../assets/icons/footer/phone.svg";
import { ReactComponent as Email } from "../../assets/icons/footer/email.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/social-media/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/social-media/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/social-media/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/social-media/linkedin.svg";

function Footer() {
  return (
    <FooterDiv>
      <FooterTop>
        <Container>
          <LeftSide>
            <LeftSide.Title>Contact Us</LeftSide.Title>
            <ItemTop>
              <ForIcon>
                <Location />
              </ForIcon>
              <div>
                329 Queensberry Street, North Melbourne VIC 3051, Australia.
              </div>
            </ItemTop>
            <div>
              <ItemLink to="tel:+1234567890">
                <ForIcon>
                  <Phone />
                </ForIcon>
                <div>123 456 7890</div>
              </ItemLink>
            </div>
            <ItemLink to="mailto:support@houzing.com">
              <ForIcon>
                <Email />
              </ForIcon>
              <div>support@houzing.com</div>
            </ItemLink>
            <SocialMedia>
              <SocialLink to="https://facebook.com" target="_blank">
                <FacebookIcon />
              </SocialLink>
              <SocialLink to="https://twitter.com" target="_blank">
                <TwitterIcon />
              </SocialLink>
              <SocialLink to="https://instagram.com" target="_blank">
                <InstagramIcon />
              </SocialLink>
              <SocialLink to="https://linkedin.com" target="_blank">
                <LinkedinIcon />
              </SocialLink>
            </SocialMedia>
          </LeftSide>
          <RightSide>
            <RightSide.Item>
              <RightSide.Title>Discover</RightSide.Title>
              <div>
                <RightSide.Link>Chicago</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Los Angeles</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Miami</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>New York</RightSide.Link>
              </div>
            </RightSide.Item>
            <RightSide.Item>
              <RightSide.Title>Lists by Category</RightSide.Title>
              <div>
                <RightSide.Link>Apartments</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Condos</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Houses</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Offices</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Retail</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Villas</RightSide.Link>
              </div>
            </RightSide.Item>
            <RightSide.Item>
              <RightSide.Title>Lists by Category</RightSide.Title>
              <div>
                <RightSide.Link>About Us</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Terms & Conditions</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Support Center</RightSide.Link>
              </div>
              <div>
                <RightSide.Link>Contact Us</RightSide.Link>
              </div>
            </RightSide.Item>
          </RightSide>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <ForLogo to="/">
            <Logo />
          </ForLogo>
          <Copyright>
            Copyright © 2021 CreativeLayers. All Right Reserved.
          </Copyright>
        </Container>
      </FooterBottom>
    </FooterDiv>
  );
}

export default Footer;
