import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HelmetComponent from "./helmet";
import { GlobalStyles } from "./global";
import ToTopButton from "./../components/ToTopButton";
import NoMatch404 from "./../pages/404";
import { navbar } from "../utils/navbar";
import Loader from "../components/Loader";

export const Root = () => {
  return (
    <>
      <HelmetComponent />
      <ToTopButton />
      <GlobalStyles />
      <Navbar />
      <Suspense fallback={<Loader />}>
        <Routes>
          {navbar.map((item, index) => (
            <Route path={item.path} element={item.element} key={index} />
          ))}
          <Route path="/" element={<Navigate to={"/home"} />} />
          <Route path="*" element={<NoMatch404 />} />
          {/* <Route path="/properties/:id" element={<ProductViewPage />} /> */}
          {/* <Route path="/register" element={<RegisterPage />} /> */}
          {/* <Route path="/auth/add-property" element={<AddPropertyPage />} /> */}
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default Root;
