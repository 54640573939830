import {
  Container,
  LogoLink,
  NavLinks,
  NavbarLink,
  NavbarLaptopDiv,
  NavButton,
} from "./navbar-laptop.styles";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { navbar } from "../../utils/navbar";

function NavbarLaptop(props) {
  return (
    <NavbarLaptopDiv>
      <Container>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <NavLinks>
          {navbar.map(
            (item, index) =>
              !item.hidden && (
                <NavbarLink to={item.path} key={index}>
                  {item.title}
                </NavbarLink>
              )
          )}
          <NavbarLink to="/properties">
            Properties
          </NavbarLink>
        </NavLinks>
        <NavButton to="login">Login</NavButton>
      </Container>
    </NavbarLaptopDiv>
  );
}

export default NavbarLaptop;
