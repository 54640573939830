import { NavbarDiv } from "./style";
import NavbarLaptop from "./navbar-laptop.component.jsx";
import NavbarMobile from "./navbar-mobile.component";

function Navbar(props) {
  return (
    <NavbarDiv>
      <NavbarLaptop />
      <NavbarMobile />
    </NavbarDiv>
  );
}

export default Navbar;
