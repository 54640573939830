import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { globalButton, globalContainer, lightBlue } from "../../root/global";

export const NavbarLaptopDiv = styled.div`
  padding: 10px 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Container = styled.div`
  ${globalContainer};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoLink = styled(Link)`
  width: 110px;
  svg {
    width: 100%;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 65px;
`;

export const NavbarLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  transition: 0.25s linear;
  &:hover {
    color: ${lightBlue};
  }
  &.active {
    color: rgb(184, 255, 6);
  }
`;

export const NavButton = styled(Link)`
  ${globalButton};
  padding: 10px 35px;
  text-decoration: none;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  &:active {
    transform: scale(0.975);
  }
`;
