import { useState } from "react";
import {
  Container,
  Hamburger,
  LogoLink,
  NavbarMobileDiv,
  LoginLink,
  Collapse,
  NavLinks,
  NavbarLink,
  SocialMedia,
  SocialLink,
} from "./navbar-mobile.style";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/navbar-hamburger.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as User } from "../../assets/icons/navbar-user.svg";
import closeIcon from "../../assets/icons/navbar-close.png";
import { ReactComponent as FacebookIcon } from "../../assets/icons/social-media/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/social-media/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/social-media/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/social-media/linkedin.svg";
import { navbar } from "../../utils/navbar";

function NavbarMobile(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const close = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <NavbarMobileDiv>
      <Container>
        <Hamburger onClick={toggleIsOpen}>
          {isOpen ? <img src={closeIcon} alt="close" /> : <HamburgerIcon />}
        </Hamburger>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <LoginLink to="/login">
          <User />
        </LoginLink>
      </Container>
      {isOpen ? (
        <Collapse>
          <div />
          <NavLinks>
            {navbar.map(
              (item, index) =>
                !item.hidden && (
                  <NavbarLink to={item.path} onClick={close} key={index}>
                    {item.title}
                  </NavbarLink>
                )
            )}
            <NavbarLink to="/properties" onClick={close}>
              Properties
            </NavbarLink>
          </NavLinks>
          <SocialMedia>
            <SocialLink
              onClick={close}
              to="https://facebook.com"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              onClick={close}
              to="https://twitter.com"
              target="_blank"
            >
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              onClick={close}
              to="https://instagram.com"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              onClick={close}
              to="https://linkedin.com"
              target="_blank"
            >
              <LinkedinIcon />
            </SocialLink>
          </SocialMedia>
        </Collapse>
      ) : null}
    </NavbarMobileDiv>
  );
}

export default NavbarMobile;
