import React from "react";
import { Container } from "./style";
import { ReactComponent as Spinner } from "../../assets/images/spinner.svg";

function Loader(props) {
  return (
    <Container>
      <Spinner />
    </Container>
  );
}

export default Loader;
