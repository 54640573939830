import { lazy } from "react";
import useId from "./../hooks/useId";

const HomePage = lazy(() => import("./../pages/Home"));
const PropertiesPage = lazy(() => import("./../pages/Properties"));
const HouseItemPage = lazy(() => import("./../pages/ProductView"));
const Register = lazy(() => import("../pages/Register"));
// const FavouritesPage = () => import("../pages/Favourites");
// const MyProfile = lazy(() => import("../pages/MyProfile"));
// const AddNewHouse = lazy(() => import("../pages/AddNewHouse"));

export const navbar = [
  {
    id: useId,
    title: "Home",
    path: "/home",
    element: <HomePage />,
    private: false,
    hidden: false,
  },
  /* {
    id: useId,
    title: "Properties",
    path: "/properties",
    element: <PropertiesPage />,
    private: false,
    hidden: false,
  }, */
  /* {
    id: useId,
    title: "Single House",
    path: "/properties/:id",
    element: <HouseItemPage />,
    private: false,
    hidden: true,
  }, */
  /* {
    id: useId,
    element: <MyProfile />,
    title: "My Profile",
    path: "/my-profile",
    private: true,
    hidden: true,
  }, */
  /* {
    id: useId,
    element: <AddNewHouse />,
    title: "Add New House",
    path: "/my-profile/newhouse",
    private: true,
    hidden: true,
  }, */
  /* {
    id: useId,
    element: <AddNewHouse />,
    title: "Add New House",
    path: "/my-profile/edithouse/:id",
    private: true,
    hidden: true,
  }, */
  {
    id: useId,
    title: "Sign In",
    path: "/signin",
    element: <Register task="SIGNIN" />,
    private: false,
    hidden: true,
  },
  /* {
    id: useId,
    title: "Favourite",
    path: "/favourites",
    element: <FavouritesPage />,
    private: true,
    hidden: true,
  }, */
  {
    id: useId,
    title: "Sign Up",
    path: "/signup",
    element: <Register task="SIGNUP" />,
    private: false,
    hidden: true,
  },
];
