import { Link } from "react-router-dom";
import { NoMatch404Container, ForImg } from "./style";
import { ReactComponent as PageNotFound } from "../../assets/images/404.svg";

const NoMatch404 = () => {
  const returnToHome = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <NoMatch404Container>
      <ForImg>
        <PageNotFound />
      </ForImg>
      <Link onClick={returnToHome} to="/home">
        Back Home
      </Link>
    </NoMatch404Container>
  );
};

export default NoMatch404;
