import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { darkBlue, globalContainer, lightBlue } from "./../../root/global";

export const NavbarMobileDiv = styled.div`
  padding: 10px 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Container = styled.div`
  ${globalContainer};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Hamburger = styled.div`
  width: 20px;
  svg {
    width: 100%;
  }
  img {
    width: 100%;
  }
  cursor: pointer;
  &:active {
    transform: scale(0.975);
  }
`;

export const LogoLink = styled(Link)`
  width: 110px;
  svg {
    width: 100%;
  }
`;

export const LoginLink = styled(Link)`
  width: 16px;
  svg {
    width: 100%;
  }
`;

export const Collapse = styled.div`
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 100%);
  max-height: calc(100vh - 100%);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const NavLinks = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const NavbarLink = styled(NavLink)`
  text-decoration: none;
  color: ${darkBlue};
  font-size: 18px;
  font-weight: 500;
  transition: 0.25s linear;
  &:hover {
    color: ${lightBlue};
  }
  &.active {
    color: rgb(184, 255, 6);
  }
`;

export const SocialMedia = styled.div`
  padding: 50px 0 100px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SocialLink = styled(Link)`
  width: 36px;
  height: 36px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 50%;
    height: 50%;
  }
  transition: 0.2s linear;
  &:hover {
    background-color: rgba(13, 38, 59, 0.1);
  }
`;
