import styled from "styled-components";
import { darkBlue, globalContainer } from "./../../root/global";

export const NavbarDiv = styled.div`
  background: ${darkBlue};
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const Container = styled.div`
  ${globalContainer}
  margin: 0 auto;
`;
