import styled from "styled-components";
import { globalButton } from "../../root/global";
import { darkBlue } from "../../root/global";

export const NoMatch404Container = styled.div`
  min-height: 50vh;
  padding-bottom: 50px;
  background-color: #f6f6f6;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    ${globalButton};
    color: #fff;
    background-color: ${darkBlue};
    text-decoration: none;
    border-radius: 3px;
    margin: 0 auto;
    padding: 10px 20px;
    &:active {
      transform: scale(0.975);
    }
  }
`;

export const ForImg = styled.div`  
  svg {
    position: relative;
    z-index: 1;
    width: 35%;

    @media (max-width: 400px) {
      width: 100%;
    }
    @media (min-width: 401px) and (max-width: 576px) {
      width: 75%;
    }
    @media (min-width: 577px) and (max-width: 992px) {
      width: 50%;
    }
  }
`;
